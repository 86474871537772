import React, { useState, useEffect } from "react";
import HeaderComponent from "../components/HeaderComponent";
import MenuComponent from "../components/MenuComponent";
import { useNavigate } from "react-router-dom";
import { IoMdHome } from "react-icons/io";
import { IoAddCircleOutline } from "react-icons/io5";
import { Modal, Button, Col, Row } from "react-bootstrap";
import { IoSearch } from "react-icons/io5";
import { deleteData } from "../services/geralService";
import {
  getUsers,
  getUserById,
  createUser,
  updateUser,
} from "../services/userService";
import { Slide, ToastContainer, toast } from "react-toastify";
import { MdEdit } from "react-icons/md";
import { FaTrashAlt } from "react-icons/fa";
import "react-toastify/dist/ReactToastify.css";

export interface User {
  id: number;
  nome: string;
  email: string;
  senha: string;
  tipo: string;
  cpf: string;
  celular: string;
  telefone: string;
  cep: string;
  endereco: string;
  numero: string;
  complemento: string;
  bairro: string;
  cidade: string;
  uf: string;
  nascimento: string;
  data_cadastro: string;
  data_alteracao: string;
}

const UsuariosPage: React.FC = () => {
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const [newUser, setNewUser] = useState({
    id: null,
    nome: "",
    email: "",
    senha: "",
    tipo: "",
    nascimento: "",
    telefone: "",
    celular: "",
    cep: "",
    endereco: "",
    numero: "",
    complemento: "",
    cidade: "",
    bairro: "",
    uf: "",
    cpf: "",
  });
  const [isEditing, setIsEditing] = useState(false);
  const [alerta, setAlerta] = useState("");
  const initialUserState = {
    id: null,
    nome: "",
    email: "",
    senha: "",
    tipo: "",
    nascimento: "",
    telefone: "",
    celular: "",
    cep: "",
    endereco: "",
    numero: "",
    complemento: "",
    cidade: "",
    bairro: "",
    uf: "",
    cpf: "",
  };
  const [search, setSearch] = useState("");

  const cancelDelete = () => {
    setShowDeleteModal(false);
    setAccountToDelete(null);
    setDescricaoRegistro("");
  };

  const handleClose = () => setShow(false);

  const add = () => {
    setNewUser(initialUserState);
    setIsEditing(false);
    setShow(true);
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [accountToDelete, setAccountToDelete] = useState<number | null>(null);

  const [descricaoRegistro, setDescricaoRegistro] = useState("");

  const handleShowDeleteModal = (id: number, descricao: any) => {
    setAccountToDelete(id);
    setDescricaoRegistro(descricao);
    setShowDeleteModal(true);
  };

  const formatDate = (dateString: any) => {
    console.log(dateString);

    if (dateString !== null) {
      const dateParts = dateString.split("-");
      return `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
    } else {
      return "";
    }
  };

  const remove = async (id: number) => {
    setAlerta("");

    let tabela = "users";

    let result = await deleteData(id, tabela);

    if (result) {
      if (result.status == "error") {
        setAlerta(result.message[0]);
      }

      if (result.status == "success") {
        setAlerta("Registro removido com sucesso.");
        fetchUsers();
      }
    }
  };

  const confirmDelete = async () => {
    if (accountToDelete !== null) {
      await remove(accountToDelete);
    }
    setShowDeleteModal(false);
    setAccountToDelete(null);
    setDescricaoRegistro("");
  };

  const edit = async (id: number) => {
    try {
      const user = await getUserById(id);

      setNewUser({
        id: user.id,
        nome: user.nome,
        email: user.email,
        senha: "",
        tipo: user.tipo,
        nascimento: formatDate(user.nascimento),
        telefone: user.telefone,
        celular: user.celular,
        cep: user.cep,
        endereco: user.endereco,
        numero: user.numero,
        complemento: user.complemento,
        cidade: user.cidade,
        bairro: user.bairro,
        uf: user.uf,
        cpf: user.cpf,
      });
      setIsEditing(true);
      setShow(true);
    } catch (error) {
      console.error("Error fetching user:", error);
    }
  };

  const notify = () => toast.info(alerta);

  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  useEffect(() => {
    if (alerta) {
      notify();
    }
  }, [alerta]);

  const fetchUsers = async (search = "") => {
    try {
      const users = await getUsers(search);
      setUsers(users);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleSearchSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (search.trim() === "" || search.trim().length >= 3) {
      fetchUsers(search.trim());
    } else {
      setAlerta("O termo de pesquisa deve ter pelo menos 3 caracteres.");
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    if (event.target.value.trim() === "") {
      fetchUsers("");
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    if (name === "cpf") {
      let cleanedValue = value.replace(/\D/g, "");

      let maskedValue = cleanedValue
        .replace(/\D/g, "")
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d{1,2})/, "$1-$2")
        .replace(/(-\d{2})\d+?$/, "$1");

      setNewUser({ ...newUser, [name]: maskedValue });
    } else if (name === "celular") {
      let cleanedValue = value.replace(/\D/g, "");

      let maskedValue = cleanedValue
        .replace(/\D/g, "")
        .replace(/(\d{2})(\d)/, "($1) $2")
        .replace(/(\d{5})(\d)/, "$1-$2")
        .replace(/(-\d{4})\d+?$/, "$1");

      setNewUser({ ...newUser, [name]: maskedValue });
    } else if (name === "telefone") {
      let cleanedValue = value.replace(/\D/g, "");

      let maskedValue = cleanedValue
        .replace(/\D/g, "")
        .replace(/(\d{2})(\d)/, "($1) $2")
        .replace(/(\d{4})(\d)/, "$1-$2")
        .replace(/(-\d{4})\d+?$/, "$1");

      setNewUser({ ...newUser, [name]: maskedValue });
    } else if (name === "cep") {
      let cleanedValue = value.replace(/\D/g, "");

      let maskedValue = cleanedValue.replace(/(\d{5})(\d)/, "$1-$2");

      setNewUser({ ...newUser, [name]: maskedValue });

      if (maskedValue.length === 9) {
        fetchAddress(maskedValue);
      }
    } else if (name === "nascimento") {
      let cleanedValue = value.replace(/\D/g, "");

      let maskedValue = cleanedValue
        .replace(/(\d{2})(\d)/, "$1/$2")
        .replace(/(\d{2})(\d)/, "$1/$2")
        .replace(/(\d{4})(\d{1,4})/, "$1/$2");

      setNewUser({ ...newUser, [name]: maskedValue });
    } else {
      setNewUser({ ...newUser, [name]: value });
    }

    if (name === "cep" && value.length === 9) {
      fetchAddress(value);
    }
  };

  const fetchAddress = async (cep: string) => {
    try {
      const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
      const data = await response.json();
      if (!data.erro) {
        setNewUser((prevUser) => ({
          ...prevUser,
          endereco: data.logradouro,
          complemento: data.complemento,
          bairro: data.bairro,
          cidade: data.localidade,
          uf: data.uf,
        }));
      } else {
        setAlerta("CEP não encontrado.");
      }
    } catch (error) {
      setAlerta("Erro ao buscar endereço. Tente novamente.");
    }
  };

  const handleSubmit = async () => {
    setAlerta("");

    await new Promise((resolve) => setTimeout(resolve, 0));

    if (!newUser.nome || !newUser.cpf || !newUser.tipo) {
      setAlerta("Os campos Nome, Cpf e Senha são obrigatórios.");
      return;
    }

    if (!isEditing && !newUser.senha) {
      setAlerta("O campo Senha é obrigatório.");
      return;
    }

    if (newUser.nome.trim().length < 3) {
      setAlerta("O nome deve ter pelo menos 3 caracteres.");
      return;
    }

    if (newUser.email.trim() != "") {
      if (!validateEmail(newUser.email.trim())) {
        setAlerta("O email deve ser válido.");
        return;
      }
    }

    if (!isEditing && newUser.senha.trim().length < 6) {
      setAlerta("A senha deve ter pelo menos 6 caracteres.");
      return;
    }

    try {
      let result: any;

      if (isEditing) {
        result = await updateUser(newUser);
      } else {
        result = await createUser(newUser);
      }

      if (result.status == "error") {
        setAlerta(result.messages[0]);
      } else {
        if (isEditing) {
          setUsers(
            users.map((user) => (user.id === newUser.id ? result.data : user))
          );
        } else {
          setUsers([...users, result]);
        }
        handleClose();
        setAlerta(
          isEditing
            ? "Usuário atualizado com sucesso."
            : "Usuário cadastrado com sucesso."
        );
      }
    } catch (error) {
      setAlerta("Não foi possível realizar esta operação.");
    }
  };

  const style = document.createElement("style");

  style.innerHTML = `
  
    .modal-dialog{
      min-width: 90%;
    }
    .modalExclusao .modal-dialog{
      min-width: 300px;
    }
  `;

  document.head.appendChild(style);

  return (
    <>
      <HeaderComponent />
      <div className="page-content">
        <MenuComponent />
        <div className="content-wrapper">
          <div className="content-inner">
            <div className="page-header page-header-light">
              <div className="breadcrumb-line breadcrumb-line-light header-elements-lg-inline">
                <div className="d-flex">
                  <div className="breadcrumb">
                    <a
                      onClick={() => navigate("/")}
                      className="breadcrumb-item"
                    >
                      <IoMdHome /> &nbsp; Início
                    </a>
                    <span className="breadcrumb-item active">Usuários</span>
                  </div>
                  <a
                    href="#"
                    className="header-elements-toggle text-body d-lg-none"
                  >
                    <i className="icon-more"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="content">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title d-flex float-left">
                    <Button className="bt-1-ico" variant="indigo" onClick={add}>
                      <IoAddCircleOutline className="m-0-auto fs-2em" />
                    </Button>
                    <span className="fs-1-5em pl-2">Usuários</span>
                  </h5>
                  <div className="float-right">
                    <form onSubmit={handleSearchSubmit}>
                      <div className="form-group form-group-feedback form-group-feedback-right mb-0 mt-1 min-w-400">
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="Pesquisar por nome ou email..."
                          value={search}
                          onChange={handleSearchChange}
                        />
                        <div className="form-control-feedback form-control-feedback-lg pt-0">
                          <button type="submit" className="btn btn-link p-0">
                            <IoSearch className="fs-1-7em" />
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-bordered table-striped">
                      <thead>
                        <tr>
                          <th style={{ width: "80px" }}>#</th>
                          <th>Nome</th>
                          <th>E-mail</th>
                          <th>Tipo</th>
                          <th style={{ width: "100px", textAlign: "center" }}>
                            Ações
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {users.map((user) => (
                          <tr key={user.id} className="cursor-pointer">
                            <td>{user.id}</td>
                            <td>{user.nome}</td>
                            <td>{user.email}</td>
                            <td>{user.tipo}</td>
                            <td className="d-flex">
                              <Button
                                variant="info"
                                size="sm"
                                onClick={() => edit(user.id)}
                              >
                                <MdEdit />
                              </Button>
                              <Button
                                variant="danger"
                                className="float-start btn-sm"
                                style={{ marginLeft: "7px" }}
                                onClick={() =>
                                  handleShowDeleteModal(user.id, user.nome)
                                }
                              >
                                <FaTrashAlt />
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>
            {isEditing ? "Editar usuário" : "Novo usuário"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={4}>
              <div className="form-group">
                <label>Nome: </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Nome"
                  name="nome"
                  value={newUser.nome}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
            <Col lg={4}>
              <div className="form-group">
                <label>E-mail: </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="E-mail"
                  name="email"
                  value={newUser.email}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
            <Col lg={2}>
              <div className="form-group">
                <label>CPF: </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="___.___.___-__"
                  name="cpf"
                  value={newUser.cpf}
                  maxLength={14}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
            <Col lg={2}>
              <div className="form-group">
                <label>Nascimento: </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="__/__/____"
                  name="nascimento"
                  maxLength={10}
                  value={newUser.nascimento}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={2}>
              <div className="form-group">
                <label>Celular: </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="(__) _____-____"
                  name="celular"
                  value={newUser.celular}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
            <Col lg={2}>
              <div className="form-group">
                <label>Telefone: </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="(__) ____-____"
                  name="telefone"
                  value={newUser.telefone}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
            <Col lg={2}>
              <div className="form-group">
                <label>CEP: </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="_____-___"
                  name="cep"
                  value={newUser.cep}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
            <Col lg={3}>
              <div className="form-group">
                <label>Endereço: </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Endereço"
                  name="endereco"
                  value={newUser.endereco}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
            <Col lg={1}>
              <div className="form-group">
                <label>Número: </label>
                <input
                  type="text"
                  className="form-control"
                  name="numero"
                  value={newUser.numero}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
            <Col lg={2}>
              <div className="form-group">
                <label>Complemento: </label>
                <input
                  type="text"
                  className="form-control"
                  name="complemento"
                  placeholder="Complemento"
                  value={newUser.complemento}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={3}>
              <div className="form-group">
                <label>Bairro: </label>
                <input
                  type="text"
                  className="form-control"
                  name="bairro"
                  placeholder="Bairro"
                  value={newUser.bairro}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
            <Col lg={3}>
              <div className="form-group">
                <label>Cidade: </label>
                <input
                  type="text"
                  className="form-control"
                  name="cidade"
                  placeholder="Cidade"
                  value={newUser.cidade}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
            <Col lg={2}>
              <div className="form-group">
                <label>UF: </label>
                <select
                  className="form-control"
                  name="uf"
                  value={newUser.uf}
                  onChange={handleInputChange}
                >
                  <option value="">-- selecione --</option>
                  <option value="AC">Acre</option>
                  <option value="AL">Alagoas</option>
                  <option value="AP">Amapá</option>
                  <option value="AM">Amazonas</option>
                  <option value="BA">Bahia</option>
                  <option value="CE">Ceará</option>
                  <option value="DF">Distrito Federal</option>
                  <option value="ES">Espírito Santo</option>
                  <option value="GO">Goiás</option>
                  <option value="MA">Maranhão</option>
                  <option value="MT">Mato Grosso</option>
                  <option value="MS">Mato Grosso do Sul</option>
                  <option value="MG">Minas Gerais</option>
                  <option value="PA">Pará</option>
                  <option value="PB">Paraíba</option>
                  <option value="PR">Paraná</option>
                  <option value="PE">Pernambuco</option>
                  <option value="PI">Piauí</option>
                  <option value="RJ">Rio de Janeiro</option>
                  <option value="RN">Rio Grande do Norte</option>
                  <option value="RS">Rio Grande do Sul</option>
                  <option value="RO">Rondônia</option>
                  <option value="RR">Roraima</option>
                  <option value="SC">Santa Catarina</option>
                  <option value="SP">São Paulo</option>
                  <option value="SE">Sergipe</option>
                  <option value="TO">Tocantins</option>
                </select>
              </div>
            </Col>
            <Col lg={2}>
              <div
                className="form-group"
                style={{ display: isEditing ? "none" : "block" }}
              >
                <label>Senha: </label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Senha"
                  name="senha"
                  value={newUser.senha}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
            <Col lg={2}>
              <div className="form-group">
                <label>Tipo: </label>
                <select
                  className="form-control"
                  name="tipo"
                  value={newUser.tipo}
                  onChange={handleInputChange}
                >
                  <option value="Comum">Comum</option>
                  <option value="Administrativo">Administrativo</option>
                </select>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            {isEditing ? "Atualizar" : "Salvar"}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showDeleteModal}
        onHide={cancelDelete}
        className="modalExclusao"
      >
        <Modal.Header>
          <Modal.Title>Confirmar exclusão</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6>
            Tem certeza que deseja excluir este registro?
            <br />
            <strong>{descricaoRegistro}</strong>
          </h6>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={cancelDelete}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            Excluir
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Slide}
      />
    </>
  );
};

export default UsuariosPage;
