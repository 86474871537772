// ComprasPage.tsx - Atualizada com controle de carrinho completo

import React, { useState, useEffect } from "react";
import HeaderComponent from "../components/HeaderComponent";
import MenuComponent from "../components/MenuComponent";
import { useNavigate } from "react-router-dom";
import { IoMdHome } from "react-icons/io";
import { Slide, ToastContainer, toast } from "react-toastify";
import { getVendedores } from "../services/vendedoresService";
import { getFazendas } from "../services/fazendaService";
import { getProdutosPorCategoria } from "../services/categoriasService";
import Cookies from "js-cookie";
import { Button, Offcanvas } from "react-bootstrap";
import { IoCartOutline } from "react-icons/io5";
import { FaMinus, FaPlus, FaRegTrashAlt } from "react-icons/fa";
import "react-toastify/dist/ReactToastify.css";

export interface Vendedor {
  id: number;
  nome: string;
  email: string;
  telefone: string;
  empresa: string;
  insumos: string;
}

interface InsumoVendedor {
  value: string;
  label: string;
  preco: string;
  categoria?: string;
}

interface Insumo {
  id: number;
  nome: string | null;
  subcategoria_nivel2_id: string | null;
  imagem: string | null;
}

interface CartItem {
  id: string;
  name: string;
  price: number;
  preco: string;
  quantity: number;
  sumPriceItem: number;
  image: string;
}

const ComprasPage: React.FC = () => {
  const [quantity, setQuantity] = useState(1);
  const [vendedores, setVendedores] = useState<Vendedor[]>([]);
  const [insumosList, setInsumosList] = useState<Insumo[]>([]);
  const [cartItems, setCartItems] = useState<CartItem[]>([]);
  const [showCart, setShowCart] = useState(false);
  const [quantities, setQuantities] = useState<{ [id: string]: number }>({});
  const navigate = useNavigate();

  const fetchVendedores = async () => {
    try {
      const data = await getVendedores();
      setVendedores(data);
    } catch (error) {
      console.error("Erro ao buscar vendedores:", error);
    }
  };

  const fetchInsumos = async () => {
    try {
      const data = await getProdutosPorCategoria("");
      setInsumosList(data);
    } catch (error) {
      console.error("Erro ao buscar insumos:", error);
      setInsumosList([]);
    }
  };

  const fetchFazendas = async () => {
    try {
      const fazendas = await getFazendas("");
      const idUser = Cookies.get("TokenBrasao");
      fazendas.filter((fazenda: any) => fazenda.idUser === idUser);
    } catch (error) {
      console.error("Erro ao buscar fazendas:", error);
    }
  };

  const updateCartDetails = () => {
    const storedCart = JSON.parse(localStorage.getItem("cart") || "[]");
    setCartItems(storedCart);
  };

  const addToCart = (insumo: InsumoVendedor, parentInsumo: Insumo | null) => {
    if (!parentInsumo) {
      toast.error("Produto inválido.");
      return;
    }

    const preco = parseFloat(insumo.preco);
    const qtdAtual = quantities[insumo.value] || 1;

    const existingCart: CartItem[] = JSON.parse(
      localStorage.getItem("cart") || "[]"
    );

    const itemIndex = existingCart.findIndex(
      (item) => item.id === insumo.value
    );

    if (itemIndex > -1) {
      // Atualiza a quantidade e o valor total do item já existente
      existingCart[itemIndex].quantity += qtdAtual;
      existingCart[itemIndex].sumPriceItem =
        existingCart[itemIndex].quantity * existingCart[itemIndex].price;
    } else {
      // Cria novo item
      const newItem: CartItem = {
        id: insumo.value,
        name: insumo.label,
        price: preco,
        preco: preco.toFixed(2),
        quantity: qtdAtual,
        sumPriceItem: preco * qtdAtual,
        image: parentInsumo.imagem || "",
      };
      existingCart.push(newItem);
    }

    localStorage.setItem("cart", JSON.stringify(existingCart));
    setCartItems(existingCart);
    toast.success("Item adicionado ao carrinho com sucesso!");
    setShowCart(true);
  };

  const removeFromCart = (id: string) => {
    const updatedCart = cartItems.filter((item) => item.id !== id);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
    setCartItems(updatedCart);
  };

  const clearCart = () => {
    localStorage.removeItem("cart");
    setCartItems([]);
  };

  const incrementQty = (id: string) => {
    setQuantities((prev) => ({
      ...prev,
      [id]: (prev[id] || 1) + 1,
    }));
  };

  const decrementQty = (id: string) => {
    setQuantities((prev) => ({
      ...prev,
      [id]: Math.max((prev[id] || 1) - 1, 1),
    }));
  };

  useEffect(() => {
    fetchVendedores();
    fetchInsumos();
    fetchFazendas();
    updateCartDetails();
  }, []);

  const formatCurrency = (value: number) =>
    new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);

  return (
    <>
      <HeaderComponent />
      <Offcanvas
        show={showCart}
        onHide={() => setShowCart(false)}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Minha Sacola</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="row cemPor">
            <button
              onClick={clearCart}
              className="btn btn-sm btn-dark br-1 mb-1 float-end"
            >
              Esvaziar Carrinho
            </button>
          </div>
          {cartItems.length > 0 ? (
            cartItems.map((item) => (
              <div key={item.id} className="mb-1">
                <hr />
                <div className="row cemPor">
                  <div className="col-md-4">
                    {item.image ? (
                      <img
                        src={item.image}
                        className="card-img-top"
                        alt={item.name || "Imagem"}
                        style={{
                          height: "100px",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      <div
                        className="card-img-top d-flex align-items-center justify-content-center"
                        style={{
                          height: "100px",
                          backgroundColor: "#f0f0f0",
                        }}
                      >
                        Sem imagem
                      </div>
                    )}
                  </div>
                  <div className="col-md-8">
                    <h6>{item.name}</h6>
                    <p className="mb-0">
                      Valor: <b>{formatCurrency(item.price)}</b>
                    </p>
                    <p className="mb-0">Quantidade: {item.quantity}</p>
                    <p className="mb-0">
                      Total: <b>{formatCurrency(item.price * item.quantity)}</b>
                    </p>
                  </div>
                  <button
                    onClick={() => removeFromCart(item.id)}
                    className="btn btn-sm btn-dark br-1 mb-1 pt-1 float-end d-flex ms-2 mt-3"
                    style={{ width: "auto" }}
                  >
                    <FaRegTrashAlt
                      style={{ cursor: "pointer", marginTop: "3px" }}
                    />
                    &nbsp; Remover item do Carrinho
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p>Carrinho vazio</p>
          )}
        </Offcanvas.Body>
      </Offcanvas>
      <div className="page-content">
        <MenuComponent />
        <div className="content-wrapper">
          <div className="page-header page-header-light">
            <div className="breadcrumb-line breadcrumb-line-light header-elements-lg-inline">
              <div className="d-flex">
                <div className="breadcrumb">
                  <a onClick={() => navigate("/")} className="breadcrumb-item">
                    <IoMdHome /> &nbsp; Início
                  </a>
                  <span className="breadcrumb-item active">Compras</span>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  {vendedores.map((vendedor) => {
                    let insumosParsed: InsumoVendedor[] = [];
                    try {
                      insumosParsed =
                        JSON.parse(vendedor.insumos || "[]") || [];
                    } catch (e) {
                      console.error("Erro no JSON de insumos", e);
                    }
                    return insumosParsed.map((insumo, index) => {
                      const parentInsumo = insumo.value
                        ? insumosList.find(
                            (item) => item.id.toString() === insumo.value
                          )
                        : undefined;
                      const safeParentInsumo: Insumo | null =
                        parentInsumo ?? null;
                      return (
                        <div
                          key={`${vendedor.id}-${index}`}
                          className="col-md-4 mb-3"
                        >
                          {safeParentInsumo && (
                            <div className="card">
                              {safeParentInsumo.imagem ? (
                                <img
                                  src={safeParentInsumo.imagem}
                                  className="card-img-top"
                                  alt={safeParentInsumo.nome || "Imagem"}
                                  style={{
                                    height: "200px",
                                    objectFit: "cover",
                                  }}
                                />
                              ) : (
                                <div
                                  className="card-img-top d-flex align-items-center justify-content-center"
                                  style={{
                                    height: "200px",
                                    backgroundColor: "#f0f0f0",
                                  }}
                                >
                                  Sem imagem
                                </div>
                              )}
                              <div className="card-body">
                                <h5 className="card-title mb-0">
                                  {safeParentInsumo.nome}
                                </h5>
                                <p className="card-text">
                                  Insumo: {insumo.label}
                                </p>
                                <p className="card-text">
                                  <b>R${insumo.preco}</b>
                                </p>
                                <div className="d-flex align-items-center gap-2 mt-2">
                                  <Button
                                    variant="outline-secondary"
                                    onClick={() => decrementQty(insumo.value)}
                                  >
                                    -
                                  </Button>
                                  <span
                                    style={{
                                      minWidth: "30px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {quantities[insumo.value] || 1}
                                  </span>
                                  <Button
                                    variant="outline-secondary"
                                    onClick={() => incrementQty(insumo.value)}
                                  >
                                    +
                                  </Button>
                                </div>
                                <Button
                                  variant="primary"
                                  onClick={() =>
                                    addToCart(insumo, safeParentInsumo)
                                  }
                                  style={{
                                    float: "left",
                                    marginLeft: "0",
                                    display: "flex",
                                    marginTop: "10px",
                                  }}
                                >
                                  Adicionar ao carrinho
                                  <IoCartOutline
                                    style={{
                                      marginTop: "3px",
                                      marginLeft: "10px",
                                    }}
                                  />
                                </Button>
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    });
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        transition={Slide}
      />
    </>
  );
};

export default ComprasPage;
